<template>
  <div>
    <h2>Bemessung Zugpfosten</h2>
    <p>Bei schlanken Systemen und bei Systemen mit Plattenlage in Scheibenlängsrichtung wird die maximale Querkraft "Vd" im Abstand der letzten Fuge vor dem Auflager und damit die massgebende Pfostenkraft bestimmt.
      Bei Systemen, die keine Zugpfosten erforderlich machen ist dieser Bemessungsteil ausgeblendet</p>
  </div>
</template>

<script>
export default {
  name: "ResultTabTwoHelp"
};
</script>

<style lang="scss">
</style>